
// import { breakpoints } from '../variables.yml';

import 'focus-visible';
import 'lazysizes';
import {LightBox} from './vendor/LightBox.js';
import Ten4Slideshow from './vendor/slideshow.js';
import './vendor/ajax.min.js';
import { LoadOMatic } from './vendor/load-o-matic.js';
import ofi from 'object-fit-images/dist/ofi.es-modules.js';
import 'intersection-observer';
import barba from '@barba/core';
import { serializeForm } from './helpers.js';

export * from './CookieConsentManager.js';

( () => {
	// nav toggle
	const toggle = document.querySelector( '.nav__toggle' );
	const nav = document.querySelector( '.nav' );
	const pattern = nav.querySelector( '.nav__pattern' );
	toggle.addEventListener( 'click', () => {
		if ( nav.classList.contains( 'open' ) ) {
			nav.classList.remove( 'open' );
			toggle.innerHTML = 'Menu';
			return;
		}
		nav.classList.add( 'open' );
		toggle.innerHTML = 'Close';
		if ( pattern ) {
			drawSvg( pattern );
		}
	} );
} )();

( () => {
	// Scroll FX
	const svg_els = document.querySelectorAll( '.svg-onscroll' );
	if ( !svg_els ) {
		return;
	}

	const observer = new IntersectionObserver( entries => {
		entries.forEach( entry => {
			if ( entry.target.tagName === 'svg' ) {
				// Handle svg
				if ( entry.isIntersecting ) {
					drawSvg( entry.target );
					observer.unobserve( entry.target );
				}
			} else {
				// handle background-color change
				if ( entry.isIntersecting ) {
					document.body.className = 'body theme js';
					document.body.classList.add( 'theme--' + entry.target.dataset.id );
				}
			}
		} );
	}, {
		rootMargin: '0px 0px 0px 0px',
		threshold: 0.1
	} );

	for ( let i = 0; i < svg_els.length; i++ ) {
		const svg = svg_els[i];
		setPadding( svg );
		observer.observe( svg );
	}

	const triggers = document.querySelectorAll( '.io-trigger' );
	if ( triggers ) {
		for ( let i = 0; i < triggers.length; i++ ) {
			const item = triggers[i];
			observer.observe( item );
		}
	}

	function setPadding( svg ) {
		const bbox = svg.getBBox();
		const padding_bottom = parseFloat( bbox.height / bbox.width * 100 ).toFixed( 2 );
		const svg_container = svg.parentElement;
		if ( svg_container ) {
			svg_container.style.paddingBottom = padding_bottom + '%';
		}
	}

} )();

( () => {
	// Slideshow
	const element = document.querySelector( '.slideshow' );
	if ( !element ) {
		return;
	}

	const slideshow = new Ten4Slideshow( element );
	const slides = slideshow.slides;
	slides[0].classList.add( 'animate' );
	slideshow.addCallback( ( index ) => {
		slides[index].classList.add( 'animate' );
		const prev = slideshow.top_slide_i;
		setTimeout( () => {
			slides[prev].classList.remove( 'animate' );
		}, 500 );
	} );
	const slide_interval = parseInt( element.getAttribute( 'data-slide-interval' ), 10 );
	window.setInterval( () => {
		slideshow.slide();
	}, slide_interval );

} )();

( () => {
	// Full Height Slideshow (list)
	const element = document.querySelector( '.full-height-slideshow--list' );
	if ( !element ) {
		return;
	}

	const slideshow = new Ten4Slideshow( element );
	const slide_interval = parseInt( element.getAttribute( 'data-slide-interval' ), 10 );
	const slideshow_pips = document.querySelectorAll( element.getAttribute( 'data-pips' ) );
	const slideshow_slides = element.querySelectorAll( '.slideshow__slide' );
	const slideshow_toggle_controls = element.querySelectorAll( '.play-fh-slider, .pause-fh-slider' );
	let timer_id = null;
	if ( slideshow_pips ) {
		for ( let i = 0; i < slideshow_pips.length; i += 1 ) {
			if ( parseInt( slideshow_pips[i].getAttribute( 'data-slide-index' ) ) === 0 ) {
				slideshow_pips[i].classList.add( 'slideshow__pip--selected' );
			}
		}
	}
	setTimeout( () => {
		slideshow_slides[0].classList.remove( 'slideshow__slide--animate-in' );
		slideshow_slides[0].classList.add( 'slideshow__slide--animate-out' );
	}, 4500 );

	if ( slideshow_pips ) {
		slideshow.addCallback( ( index ) => {
			for ( let i = 0; i < slideshow_pips.length; i += 1 ) {
				if ( parseInt( slideshow_pips[i].getAttribute( 'data-slide-index' ) ) === index ) {
					slideshow_pips[i].classList.add( 'slideshow__pip--selected' );
					continue;
				}
				slideshow_pips[i].classList.remove( 'slideshow__pip--selected' );
			}
		} );
	}

	function resetInterval() {
		window.clearInterval( timer_id );
		timer_id = window.setInterval( () => {
			slideshow.slide();
		}, slide_interval );
	}

	function handlePipClick( pip ) {
		return () => {
			if ( element.querySelector( '.play-fh-slider' ).classList.contains( 'hidden-control' ) ) {
				resetInterval();
			}
			pip.classList.add( 'slideshow__pip--selected' );
			slideshow.slideTo( parseInt( pip.getAttribute( 'data-slide-index' ) ) );
		};
	}

	if ( slideshow_pips ) {
		for ( let i = 0; i < slideshow_pips.length; i += 1 ) {
			slideshow_pips[i].addEventListener( 'click', handlePipClick( slideshow_pips[i] ) );
		}
	}

	function handleControlClick( target ) {
		target.parentElement.querySelector( '.hidden-control' ).classList.toggle( 'hidden-control' );
		target.classList.toggle( 'hidden-control' );
		if ( target.getAttribute( 'data-action' ) === 'pause' ) {
			window.clearInterval( timer_id );
		} else {
			resetInterval();
		}

	}

	if ( slideshow_toggle_controls ) {
		for ( let i = 0; i < slideshow_toggle_controls.length; i++ ) {
			slideshow_toggle_controls[i].addEventListener( 'click', ( e ) => {
				handleControlClick( e.target );
			}, true );
		}
	}

	resetInterval();

} )();

( () => {
	// Full Height Slideshow (thumbs)
	const element = document.querySelector( '.full-height-slideshow--thumbs' );
	if ( !element ) {
		return;
	}

	const slideshow = new Ten4Slideshow( element );
	const slide_interval = parseInt( element.getAttribute( 'data-slide-interval' ), 10 );
	const slideshow_pips = document.querySelectorAll( element.getAttribute( 'data-pips' ) );
	const slideshow_slides = element.querySelectorAll( '.slideshow__slide' );
	const slideshow_toggle_controls = element.querySelectorAll( '.play-fh-slider, .pause-fh-slider' );
	let timer_id = null;
	if ( slideshow_pips ) {
		for ( let i = 0; i < slideshow_pips.length; i += 1 ) {
			if ( parseInt( slideshow_pips[i].getAttribute( 'data-slide-index' ) ) === 0 ) {
				slideshow_pips[i].classList.add( 'slideshow__pip--selected' );
			}
		}
	}
	setTimeout( () => {
		slideshow_slides[0].classList.remove( 'slideshow__slide--animate-in' );
		slideshow_slides[0].classList.add( 'slideshow__slide--animate-out' );
	}, 4500 );

	if ( slideshow_pips ) {
		slideshow.addCallback( ( index ) => {
			for ( let i = 0; i < slideshow_pips.length; i += 1 ) {
				if ( parseInt( slideshow_pips[i].getAttribute( 'data-slide-index' ) ) === index ) {
					slideshow_pips[i].classList.add( 'slideshow__pip--selected' );
					continue;
				}
				slideshow_pips[i].classList.remove( 'slideshow__pip--selected' );
			}
		} );
	}

	function resetInterval() {
		window.clearInterval( timer_id );
		timer_id = window.setInterval( () => {
			slideshow.slide();
		}, slide_interval );
	}

	function handlePipClick( pip ) {
		return () => {
			if ( element.querySelector( '.play-fh-slider' ).classList.contains( 'hidden-control' ) ) {
				resetInterval();
			}
			slideshow.slideTo( parseInt( pip.getAttribute( 'data-slide-index' ) ) );
			pip.classList.add( 'slideshow__pip--selected' );
		};
	}

	if ( slideshow_pips ) {
		for ( let i = 0; i < slideshow_pips.length; i += 1 ) {
			slideshow_pips[i].addEventListener( 'click', handlePipClick( slideshow_pips[i] ) );
		}
	}

	function handleControlClick( target ) {
		target.parentElement.querySelector( '.hidden-control' ).classList.toggle( 'hidden-control' );
		target.classList.toggle( 'hidden-control' );
		if ( target.getAttribute( 'data-action' ) === 'pause' ) {
			window.clearInterval( timer_id );
		} else {
			resetInterval();
		}

	}

	if ( slideshow_toggle_controls ) {
		for ( let i = 0; i < slideshow_toggle_controls.length; i++ ) {
			slideshow_toggle_controls[i].addEventListener( 'click', ( e ) => {
				handleControlClick( e.target );
			}, true );
		}
	}

	resetInterval();

} )();

( () => {
	// Slideshow
	const element = document.querySelector( '.client-slideshow' );
	if ( !element ) {
		return;
	}

	const slideshow = new Ten4Slideshow( element );
	const slides = slideshow.slides;
	const slideshow_toggle_controls = element.querySelectorAll( '.play-fh-slider, .pause-fh-slider' );
	let timer_id = null;
	slides[0].classList.add( 'animate' );
	slideshow.addCallback( ( index ) => {
		slides[index].classList.add( 'animate' );
		const prev = slideshow.top_slide_i;
		setTimeout( () => {
			slides[prev].classList.remove( 'animate' );
		}, 1000 );
	} );
	const slide_interval = parseInt( element.getAttribute( 'data-slide-interval' ), 10 );
	function resetInterval() {
		window.clearInterval( timer_id );
		timer_id = window.setInterval( () => {
			slideshow.slide();
		}, slide_interval );
	}

	function handleControlClick( target ) {
		target.parentElement.querySelector( '.hidden-control' ).classList.toggle( 'hidden-control' );
		target.classList.toggle( 'hidden-control' );
		if ( target.getAttribute( 'data-action' ) === 'pause' ) {
			window.clearInterval( timer_id );
		} else {
			resetInterval();
		}

	}

	if ( slideshow_toggle_controls ) {
		for ( let i = 0; i < slideshow_toggle_controls.length; i++ ) {
			slideshow_toggle_controls[i].addEventListener( 'click', ( e ) => {
				handleControlClick( e.target );
			}, true );
		}
	}

	resetInterval();

} )();

( () => {
	// Slideshow
	const element = document.querySelector( '.projects-slideshow' );
	if ( !element ) {
		return;
	}

	const slideshow = new Ten4Slideshow( element );
	const slides = slideshow.slides;
	const slideshow_toggle_controls = element.querySelectorAll( '.play-fh-slider, .pause-fh-slider' );
	let timer_id = null;
	slides[0].classList.add( 'animate' );
	slideshow.addCallback( ( index ) => {
		slides[index].classList.add( 'animate' );
		const prev = slideshow.top_slide_i;
		setTimeout( () => {
			slides[prev].classList.remove( 'animate' );
		}, 1000 );
	} );
	const slide_interval = parseInt( element.getAttribute( 'data-slide-interval' ), 10 );
	function resetInterval() {
		window.clearInterval( timer_id );
		timer_id = window.setInterval( () => {
			slideshow.slide();
		}, slide_interval );
	}

	function handleControlClick( target ) {
		target.parentElement.querySelector( '.hidden-control' ).classList.toggle( 'hidden-control' );
		target.classList.toggle( 'hidden-control' );
		if ( target.getAttribute( 'data-action' ) === 'pause' ) {
			window.clearInterval( timer_id );
		} else {
			resetInterval();
		}

	}

	if ( slideshow_toggle_controls ) {
		for ( let i = 0; i < slideshow_toggle_controls.length; i++ ) {
			slideshow_toggle_controls[i].addEventListener( 'click', ( e ) => {
				handleControlClick( e.target );
			}, true );
		}
	}

	resetInterval();

} )();

function testimonial_slider() {
	// Slideshow
	const element = document.querySelector( '.testimonial-slideshow' );
	if ( !element ) {
		return;
	}

	const slideshow = new Ten4Slideshow( element );
	const slides = slideshow.slides;
	const slideshow_toggle_controls = element.parentElement.querySelectorAll( '.play-fh-slider, .pause-fh-slider' );
	let timer_id = null;
	slides[0].classList.add( 'animate' );
	slideshow.addCallback( ( index ) => {
		slides[index].classList.add( 'animate' );
		const prev = slideshow.top_slide_i;
		setTimeout( () => {
			slides[prev].classList.remove( 'animate' );
		}, 1000 );
	} );
	const slide_interval = parseInt( element.getAttribute( 'data-slide-interval' ), 10 );
	function resetInterval() {
		window.clearInterval( timer_id );
		timer_id = window.setInterval( () => {
			slideshow.slide();
		}, slide_interval );
	}

	function handleControlClick( target ) {
		target.parentElement.querySelector( '.hidden-control' ).classList.toggle( 'hidden-control' );
		target.classList.toggle( 'hidden-control' );
		if ( target.getAttribute( 'data-action' ) === 'pause' ) {
			window.clearInterval( timer_id );
		} else {
			resetInterval();
		}

	}

	if ( slideshow_toggle_controls ) {
		for ( let i = 0; i < slideshow_toggle_controls.length; i++ ) {
			slideshow_toggle_controls[i].addEventListener( 'click', ( e ) => {
				handleControlClick( e.target );
			}, true );
		}
	}

	resetInterval();

}
testimonial_slider();

( () => {
	// Animation controls
	const animation_controls = document.querySelectorAll( '.animation-control' );
	if ( !animation_controls ) {
		return;
	}

	for ( let i = 0; i < animation_controls.length; i++ ) {
		animation_controls[i].addEventListener( 'click', () => {
			const animation = document.querySelector( animation_controls[i].getAttribute( 'data-animation' ) );

			animation.togglePlay();

			const animation_control_siblings = animation_controls[i].parentElement.querySelectorAll( '.animation-control.hidden-control' );

			for ( let j = 0; j < animation_control_siblings.length; j++ ) {
				animation_control_siblings[j].classList.remove( 'hidden-control' );
			}
			animation_controls[i].classList.add( 'hidden-control' );
		} );
	}
} )();

( () => {
	// Video embeds
	const consent_wrapper = document.querySelectorAll( '.cookie-consent-wrapper' );
	if ( consent_wrapper ) {
		customEventHandler( '.cookie-consent-wrapper', '.video', 'click' );
	}
	const videos = document.querySelectorAll( '.video-embed' );
	if ( videos ) {
		for ( let j = 0; j < videos.length; j += 1 ) {
			videos[j].addEventListener( 'click', handleVideoClick( videos[j] ) );
		}
	}

	function handleVideoClick( container ) {
		return function( event ) {
			event.preventDefault();
			buildVideoPlayer( container );
		};
	}

	function buildVideoPlayer( container ) {
		const embed_url = container.getAttribute( 'data-embed-url' );
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'width', 1640 );
		iframe.setAttribute( 'height', 923 );
		iframe.setAttribute( 'src', embed_url );
		iframe.setAttribute( 'title', 'Embedded video content' );
		iframe.setAttribute( 'frameborder', 0 );
		iframe.setAttribute( 'allowfullscreen', true );
		container.classList.add( 'flex-container' );
		container.classList.add( 'video-embed--playing' );
		container.innerHTML = '';
		container.appendChild( iframe );
	}

	// Cookie policy blocks event handler from being attached directly
	function customEventHandler( root_selector, selector, event ) {
		const root_el = document.querySelectorAll( root_selector );
		for ( let i = 0; i < root_el.length; i++ ) {
			const el = root_el[i];
			el.addEventListener( event, ( e ) => {
				let target = e.currentTarget;
				while ( target !== null ) {
					if ( target.matches( selector ) ) {
						buildVideoPlayer( target.querySelector( '.video-embed' ) );
						return;
					}
					target = target.parentElement;
				}
			}, true );
		}
	}

} )();

( () => {
	// Display time for timezone filter
	const container = document.querySelector( '.filter' );
	if ( !container ) {
		return;
	}
	const current_filter = document.querySelector( '.filter__link--selected' );
	const current_timezone = current_filter.getAttribute( 'data-timezone' );
	const timezones = {
		gmt: {
			dst: 1,
			standard: 0,
		},
		est: {
			dst: -4,
			standard: -5
		},
		pst: {
			dst: -7,
			standard: -8
		},
		cst: {
			dst: -5,
			standard: -6
		}
	};

	if ( current_timezone ) {
		current_filter.innerHTML += ' - currently ' + renderTime( timezones[current_timezone] );
	}

	function renderTime( current_timezone ) {
		const d = new Date();
		const local_time = d.getTime();
		const local_offset = d.getTimezoneOffset() * 60 * 1000;
		const utc_time = local_time + local_offset;
		const offset = getTimezoneOffset( current_timezone );
		const offset_time = utc_time + ( 60 * 60 * 1000 * offset );
		const nd = new Date( offset_time );
		const time_string = ( '0' + nd.getHours() ).slice( -2 ) + ':' + ( '0' + nd.getMinutes() ).slice( -2 );
		return time_string;
	}

	function getTimezoneOffset( tz ) {
		const stdTimezoneOffset = () => {
			const jan = new Date( 0, 1 );
			const jul = new Date( 6, 1 );
			return Math.max( jan.getTimezoneOffset(), jul.getTimezoneOffset() );
		};
		const today = new Date();
		const dst_observed = ( today ) => {
			return today.getTimezoneOffset() < stdTimezoneOffset();
		};

		if ( dst_observed( today ) ) {
			return tz.dst;
		} else {
			return tz.standard;
		}
	}

} )();

( () => {
	function doToggle( toggler ) {
		const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
		for ( let i = 0; i < togglees.length; i += 1 ) {
			togglees[i].classList.toggle( 'toggled' );
		}
		toggler.classList.toggle( 'toggler--toggled' );
	}

	window.addEventListener( 'click', ( event ) => {
		if ( !event.target.classList.contains( 'toggler' ) ) {
			return;
		}
		event.preventDefault();
		doToggle( event.target );
	} );
} )();

( () => {
	// Carousel
	const carousel_pagination = document.querySelectorAll( '.carousel-pagination' );

	function scrollCarousel( button ) {
		if ( button.getAttribute( 'data-carousel' ) ) {
			const carousel = document.querySelector( '#' + button.getAttribute( 'data-carousel' ) );
			const slides = carousel.querySelectorAll( '.carousel__item' );
			let active_slide = {};

			for ( let i = 0; i < slides.length; i++ ) {
				const rect = slides[i].getBoundingClientRect();
				let offset = 0;
				if ( rect.x < 1 ) {
					offset = Math.abs( rect.x );
				} else {
					offset = rect.x;
				}
				if ( ( Object.keys( active_slide ).length === 0 || active_slide.x > offset ) ) {
					active_slide = { 'x': offset, 'index': i };
				}
			}

			if ( button.getAttribute( 'data-direction' ) ) {
				const direction = button.getAttribute( 'data-direction' );
				const style = carousel.currentStyle || window.getComputedStyle( carousel );

				if ( direction === 'next' ) {
					let next_slide_index = 0;
					if ( ( active_slide.index + 1 ) < slides.length ) {
						next_slide_index = active_slide.index + 1;
					} else {
						next_slide_index = active_slide.index;
					}

					const right_padding = parseInt( style.paddingRight, 10 );
					carousel.scrollLeft = ( slides[ next_slide_index ].offsetLeft ) - right_padding ;
				} else if ( direction === 'prev' ) {

					let prev_slide_index = 0;
					if ( ( active_slide.index - 1 ) > -1 ) {
						prev_slide_index = active_slide.index - 1;
					} else {
						prev_slide_index = active_slide.index;
					}

					const left_padding = parseInt( style.paddingLeft, 10 );
					carousel.scrollLeft = ( slides[ prev_slide_index ].offsetLeft ) - ( 32 + left_padding );
				}
			}
		}
	}

	for ( let j = 0; j < carousel_pagination.length; j++ ) {
		carousel_pagination[j].addEventListener( 'click', () => {
			event.preventDefault();
			scrollCarousel( event.target );
		} );
	}
} )();

( () => {
	// Fold outs
	const fo_toggles = document.querySelectorAll( '.fold-out-toggle' );
	if ( !fo_toggles ) {
		return;
	}
	let fo = null;

	window.addEventListener( 'load', () => {
		getSearchParams();
	} );

	fo_toggles.forEach( toggle => {
		toggle.setAttribute( 'tabindex', '0' );

		toggle.addEventListener( 'click', ( e ) => {
			e.preventDefault();
			openFoldOut( toggle );
		} );
		toggle.addEventListener( 'keyup', ( e ) => {
			if ( e.key === 'Enter' ) {
				e.preventDefault();
				openFoldOut( toggle );
			}
		} );
	} );

	function getSearchParams() {
		const url_params = new URLSearchParams( window.location.search );
		const name = url_params.get( 'name' );

		if ( name ) {
			document.body.classList.toggle( 'fold-out-open', true );
		}
	}

	function openFoldOut( toggle ) {
		fo = document.querySelector( toggle.getAttribute( 'data-fold-out' ) );
		const person = toggle.getAttribute( 'data-person-slug' );
		fo.classList.add( 'open' );
		document.body.classList.toggle( 'fold-out-open', true );
		history.pushState( null, '', '?name=' + person );
		window.addEventListener( 'click', handleCloseFoldOut );
		window.addEventListener( 'keyup', ( event ) => {
			if ( event.key === 'Enter' ) {
				handleCloseFoldOut( event );
			}
		} );
	}

	function handleCloseFoldOut( event ) {
		if ( event.target.classList.contains( 'fold-out__close' ) || event.target.classList.contains( 'fold-out' ) ) {
			closeFoldOut();
		}
	}

	function closeFoldOut() {
		const url = window.location.href.split( '?' );
		fo.classList.remove( 'open' );
		document.body.classList.toggle( 'fold-out-open', false );

		history.pushState( null, '', url[0] );
		window.removeEventListener( 'click', handleCloseFoldOut );
	}
} )();

function init_lightboxes() {
	// Lightbox
	const lightbox_triggers = document.querySelectorAll( '.lightbox-trigger' );
	if ( !lightbox_triggers ) {
		return;
	}

	new LightBox();
}
init_lightboxes();

( () => {
	// Barba
	const barba_wrapper = document.querySelector( '[data-barba="wrapper"]' );
	if ( barba_wrapper ) {
		barba.init( {
			logLevel: 'error',
			transitions: [ {
				sync: false,
				name: 'opacity-transition',
				from: {
					namespace: [
						'projects'
					]
				},
				to: {
					namespace: [
						'projects'
					]
				},
				before( data ) {
					if ( data.next.url.path.includes( '/projects' ) ) {
						document.querySelector( '[data-barba="wrapper"]' ).style.opacity = "0";
						document.querySelector( '#transition-overlay' ).classList.add( 'fade-out' );
					} else {
						barba.force( data.next.url.href );
						barba.destroy();
					}
				},
				leave() {
				},
				after( data ) {
					if ( data.next.url.path.includes( '/projects' ) ) {
						const main_content = document.querySelector( '#main-content' );
						let scroll_to = main_content.offsetTop;
						if ( scroll_to === 0 ) {
							scroll_to = scroll_to + document.querySelector( '#top' ).clientHeight;
						}
						window.scrollTo( 0, scroll_to );
						testimonial_slider();
						init_lightboxes();

						setTimeout( function () { document.querySelector( '[data-barba="wrapper"]' ).style.opacity = "1"; }, 550 );
						setTimeout( function () { document.querySelector( '#transition-overlay' ).classList.remove( 'fade-out' ); }, 600 );
					}
				}
			} ]
		} );
	}
} )();

function drawSvg( svg, transition_in_s = 5 ) {
	const svg_paths = svg.querySelectorAll( 'path' );
	for ( let i = 0; i < svg_paths.length; i++ ) {
		const path = svg_paths[i];
		const path_length = path.getTotalLength();
		path.style.transition = path.style.WebkitTransition = 'none';
		path.style.strokeDasharray = path_length + ' ' + path_length;
		path.style.strokeDashoffset = path_length;
		path.getBoundingClientRect();
		path.style.transition = path.style.WebkitTransition = `stroke-dashoffset ${transition_in_s}s ease-in-out`;
		path.style.strokeDashoffset = '0';
		svg.style.opacity = 1;
	}
}
setSlideShowHeight();
function setSlideShowHeight() {
	const slider_labels = document.querySelectorAll( '.full-screen-section .full-screen-section__slider .full-screen-section__slider__labels' );
	let slider = null;
	let slider_container = null;
	let slider_container_height = 0;
	let slider_styles;
	let slider_padding_top = null;
	let slider_padding_bottom = null;
	for ( let i=0; i<slider_labels.length; i++ ) {
		slider_container_height = slider_labels[i].offsetHeight;

		slider = slider_labels[i].parentNode;


		slider_styles = getComputedStyle( slider );
		slider_padding_top =  parseInt( slider_styles.paddingTop, 10 );
		slider_padding_bottom =  parseInt( slider_styles.paddingBottom, 10 );

		slider_container = slider.parentNode;
		slider_container.style.height = slider_container_height +  slider_padding_top +  slider_padding_bottom  + 'px';
	}
}

window.addEventListener( 'DOMContentLoaded', () => {
	ofi();
} );

( () => {
	// Copy link
	const copy_link_button = document.getElementById( 'copy_link' );

	if ( ! copy_link_button ) {
		return;
	}

	copy_link_button.addEventListener( 'click', ( event ) => {
		event.preventDefault();

		const link_to_copy = window.location.href;
		const temp_input = document.createElement( 'input' );
		temp_input.value = link_to_copy;
		document.body.appendChild( temp_input );
		temp_input.select();
		temp_input.setSelectionRange( 0, 99999 );
		document.execCommand( 'copy' );
		document.body.removeChild( temp_input );
		copy_link_button.textContent = 'Link Copied!';
	} );

} )();

let loadomatic;
// eslint-disable-next-line snakecasejs/snakecasejs
const buildLoadOMatic = ( options ) => {
	const container = document.querySelector( '.loadomatic' );
	if ( !container ) {
		return;
	}

	loadomatic = new LoadOMatic( options );

	const observer = new IntersectionObserver( ( items ) => {
		for ( let i = 0; i < items.length; i++ ) {
			if ( items[i].isIntersecting ) {
				loadomatic.loadMore();
			}
		}
	}, {
		rootMargin: '-50px 0px -50px 0px',
	} );
	observer.observe(
		document.querySelector( '.loadomatic__elements' )
	);
};

( () => {
	// Filters
	const segments = window.location.pathname.split( '/' );
	const filters_form = document.getElementById( 'js-listing-filters' );
	const pagination_message = document.getElementById( 'js-pagination-message' );
	const results_container = document.querySelector( '.loadomatic' );
	const checkboxes = filters_form.querySelectorAll( 'input[type=checkbox]' );

	for ( let i = 0; i < checkboxes.length; i++ ) {
		checkboxes[ i ].addEventListener( 'change', ( e ) => {
			const checkbox_name = e.target.name;
			const checkbox_checked = e.target.checked;
			const checkbox_value = e.target.value;
			const checkboxes = filters_form.querySelectorAll( 'input[name=\'' + checkbox_name + '\']' );

			if ( checkbox_value === 'all' && checkbox_checked ) {
				for ( let j = 0; j < checkboxes.length; j++ ) {
					if ( checkboxes[ j ].value !== 'all' && checkboxes[ j ].checked ) {
						checkboxes[ j ].checked = false;
					}
				}
			} else if ( checkbox_checked ) {
				for ( let j = 0; j < checkboxes.length; j++ ) {
					if ( checkboxes[ j ].value === 'all' && checkboxes[ j ].checked ) {
						checkboxes[ j ].checked = false;
					}
				}
			} else {
				const checkboxes_checked = filters_form.querySelectorAll( 'input[name=\'' + checkbox_name + '\']:checked' );

				if ( checkboxes_checked.length === 0 ) {
					for ( let j = 0; j < checkboxes.length; j++ ) {
						if ( checkboxes[ j ].value === 'all' ) {
							checkboxes[ j ].checked = true;
						}
					}
				}
			}
		} );
	}

	if ( !filters_form || !results_container ) {
		return;
	}

	filters_form.addEventListener( 'submit', ( e ) => {
		e.preventDefault();
		handleFormChange();
	} );

	filters_form.addEventListener( 'change', () => {
		handleFormChange();
	} );

	function getUrlFromForm( form, clear ) {
		const form_data = serializeForm( form );
		if ( clear ) {
			return segments[1] + '?' + form_data[0];
		}
		const query_string = form_data.join( '&' );
		return segments[1] + '?' + query_string;
	}

	function handleFormChange( clear = false ) {
		const search_url = getUrlFromForm( filters_form, clear );
		if ( pagination_message ) {
			pagination_message.classList.add( 'removed' );
		}
		window.history.pushState( null, null, search_url );
		loadomatic.resetEnd();
		loadomatic.loadMore( true, false );
	}

} )();

window.runPageScript = () => {
	if ( typeof window.cbx === 'function' ) {
		window.cbx( {
			buildLoadOMatic: buildLoadOMatic,
		} );
	}
};

window.runPageScript();
