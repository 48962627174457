export function serializeForm( form ) {
	if ( !form || form.nodeName !== 'FORM' ) {
		return;
	}
	const result = [];
	for ( let i = 0; i < form.elements.length; i++ ) {
		if ( form.elements[i].name === '' || form.elements[i].value === '' ) {
			continue;
		}
		switch ( form.elements[i].nodeName ) {
			case 'INPUT':
				switch ( form.elements[i].type ) {
					case 'text':
					case 'date':
					case 'hidden':
					case 'password':
					case 'button':
					case 'reset':
					case 'search':
					case 'submit':
						result.push( form.elements[i].name + '=' + encodeURIComponent( form.elements[i].value ) );
						break;
					case 'checkbox':
					case 'radio':
						if ( form.elements[i].checked ) {
							result.push( form.elements[i].name + '=' + encodeURIComponent( form.elements[i].value ) );
						}
						break;
					case 'file':
						break;
				}
				break;
			case 'TEXTAREA':
				result.push( form.elements[i].name + '=' + encodeURIComponent( form.elements[i].value ) );
				break;
			case 'SELECT':
				switch ( form.elements[i].type ) {
					case 'select-one':
						result.push( form.elements[i].name + '=' + encodeURIComponent( form.elements[i].value ) );
						break;
					case 'select-multiple':
						for ( let j = 0; j < form.elements[i].options.length; j++ ) {
							if ( form.elements[i].options[j].selected ) {
								result.push( form.elements[i].name + '=' + encodeURIComponent( form.elements[i].options[j].value ) );
							}
						}
						break;
				}
				break;
			case 'BUTTON':
				switch ( form.elements[i].type ) {
					case 'reset':
					case 'submit':
					case 'button':
						result.push( form.elements[i].name + '=' + encodeURIComponent( form.elements[i].value ) );
						break;
				}
				break;
		}
	}
	return result;
}

