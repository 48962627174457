export class LoadOMatic {
	constructor(
		append_container
	) {
		this.reached_end = false;

		this.append_container = document.querySelector( '.loadomatic' );
		this.message = document.querySelector( '.loadomatic__message' );
		this.message.appendChild( document.createElement( 'p' ) );
		//this.message.querySelector( 'p' ).innerHTML = 'Loading more....';
		this.trigger = document.querySelector( '.loadomatic__trigger' );

		this.callbacks = [];

		this.handleTrigger();
	}

	getCurrentPage() {
		return parseInt(
			this.getQueryObject( window.location.search ).page || '1', 10
		);
	}

	resetEnd() {
		this.reached_end = false;
		this.trigger.classList.remove( 'loadomatic__trigger--hidden' );
	}

	loadMore( replace_all = false, increment_page = true ) {
		console.log('la');
		if ( this.reached_end ) {
			return;
		}
		let url;
		if ( increment_page ) {
			url = this.getUrl( this.getCurrentPage() + 1 );
		} else {
			url = this.getUrl();
		}
		this.trigger.classList.add( 'loadomatic__trigger--hidden' );
		this.message.classList.remove( 'loadomatic__message--hidden' );
		ajax( {
			type: 'get',
			url: url,
			data: {},
			headers: [
				['X-LoadOMatic', 'yes'],
			],
			timeout: 5000,
			onSuccess: ( data, status ) => {
				if (
					data.trim() === ''
					|| ( replace_all === false && status === 202 )
				) {
					this.reached_end = true;
				} else {
					if ( replace_all ) {
						this.append_container.innerHTML = data;
					} else {
						this.append_container.innerHTML += data;
					}
					for ( let i = 0; i < this.callbacks.length; i++ ) {
						this.callbacks[i]();
					}
					if ( window.history.pushState ) {
						window.history.pushState( null, null, url );
					}
				}
				this.handleTrigger();
			},
			onFailure: this.handleFailure(),
		} );
	}

	handleTrigger( delay ) {
		this.message.classList.add( 'loadomatic__message--hidden' );
		if ( this.reached_end ) {
			this.trigger.classList.add( 'loadomatic__trigger--hidden' );
			return;
		}
		setTimeout( () => {
			this.trigger.classList.remove( 'loadomatic__trigger--disabled' );
		}, delay );
	}

	handleFailure() {
		return () => {
			this.message.classList.remove( 'loadomatic__message--hidden' );
			this.trigger.classList.add( 'loadomatic__trigger--hidden' );
			this.message.querySelector( 'p' ).innerHTML = 'Sorry, there was a problem retrieving content.';
		}
	}

	getQueryObject( query_string ) {
		const query_object = {};
		const pairs = ( query_string[0] === '?' ? query_string.substr( 1 ) : query_string ).split( '&' );
		for ( let i = 0; i < pairs.length; i++ ) {
			const pair = pairs[i].split( '=' );
			if (pair != '' ) {
				let key = decodeURIComponent( pair[0] );
				const value = decodeURIComponent( pair[1] || '' );
				if ( key.substr( -2 ) === '[]' ) {
					key = key.substr( 0, key.length - 2 );
					if ( !( query_object[key] instanceof Array ) ) {
						query_object[key] = [];
					}
					query_object[key].push( value );
					continue;
				}
				query_object[key] = value;
			}
		}

		return query_object;
	}

	getQueryString( query_object ) {
		const query_pieces = [];
		for ( const key in query_object ) {
			if ( !query_object.hasOwnProperty( key ) ) {
				continue;
			}
			if ( query_object[key] instanceof Array ) {
				for ( let i = 0; i < query_object[key].length; i++ ) {
					query_pieces.push( key + '[]' + ( query_object[key][i] ? '=' + query_object[key][i] : '' ) );
				}
				continue;
			}
			query_pieces.push( key + ( query_object[key] ? '=' + query_object[key] : '' ) );
		}

		if ( query_pieces.length === 0 ) {
			return '';
		}
		if ( !query_pieces.includes( 'loadmore=1' ) ) {
			query_pieces.push(  'loadmore=1' );
		}
		return '?' + query_pieces.join( '&' );
	}

	getUrl( page = null ) {
		const query_object = this.getQueryObject( window.location.search );
		//if ( query_object.hasOwnProperty( 'page' ) ) {
		//	query_object.page = intval( query_object.page ) + 1;
		//}
		if ( page !== null ) {
			query_object.page = page;
		}
		const query_string = this.getQueryString( query_object );

		return `${window.location.pathname}${query_string}`;
	}

	addCallback( fx ) {
		this.callbacks.push( fx );
	}
}